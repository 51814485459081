// import { Button } from "@mui/material";
import React from "react";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const GridElement = ({ imageUrl, description, desc }) => {
  return (
    <div className="bg-[#F5F5F5] rounded-2xl p-4 m-2 text-start shadow-md">
      <div className="w-[80px] h-[100px] mb-5">
        <img
          src={imageUrl}
          alt=".."
          className="w-full h-auto rounded-full mb-2"
        />
      </div>
      <p className="text-start text-2xl font-semibold	mb-6 text-[#2A82C2]">
        {description}
      </p>
      <p className="mb-6">{desc}</p>
      {/* <Button
        variant="outlined"
        color="primary"
        className="flex items-center gap-2"
      >
        <span className="font-semibold">Learn More</span>
        <ArrowForwardIcon fontSize="small" />
      </Button> */}
    </div>
  );
};

const Grid = () => {
  const elements = [
    {
      imageUrl: "/assets/images/srvc1.png",
      description: "Tenant Search in Bangalore",
      desc: "A large, expanded network helps us speed up your tenant search.",
    },
    {
      imageUrl: "/assets/images/inspection.png",
      description: "Detailed Inspection",
      desc: "We do a detailed Inspection at the time of taking over the management and provide a well-documented inspection report along with issue list and estimates.",
    },
    {
      imageUrl: "/assets/images/download.jpeg",
      description: "Photoshoot of Property",
      desc: "Get clear insight of the properties through images.",
    },
    {
      imageUrl:
        "/assets/images/stock-vector-agreement-icon-vector-image-can-also-be-used-for-real-estate-suitable-for-mobile-apps-we.jpg",
      description: "Rental Agreement Signing",
      desc: "Prepare rental agreements with the tenants as per your terms.",
    },
    {
      imageUrl: "/assets/images/Background+Check.png",
      description: "Handover from Tenants / To Tenants",
      desc: "We provide a Detailed Documentation of Handover from outgoing / incoming tenants.",
    },
    {
      imageUrl: "/assets/images/rent.png",
      description: "On-Time Rent & Deposit Collection",
      desc: " We follow-up with the tenants for rentals and deposits, deduction of proper taxes etc.",
    },
    {
      imageUrl: "/assets/images/inspection.png",
      description: "Periodic Home Inspection",
      desc: "A large, expanded network helps us speed up your tenant search",
    },
    {
      imageUrl:
        "/assets/images/360_F_648793438_sQOTHD4QwQxCXM44fqlHf2O4auwOezje.jpg",
      description: "Help in Property Tax Payment",
      desc: "We track the due dates, provide reminders and help with the property tax payments.",
    },
    {
      imageUrl: "/assets/images/rupee.png",
      description: "On-Demand Repair Service",
      desc: "Our team of vendors can take care of your various maintenance services like Painting Services, all kinds of Repairs, Pest Control, Chimney Cleaning, Storage facilities etc.",
    },
  ];

  return (
    <div className="mb-[100px]">
      <div>
        <h1 className="text-[44px] font-semibold text-center mt-[50px] mb-[50px]">
          Property Management Services
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mx-auto px-[20px] max-w-[1800px]">
        {elements.map((element, index) => (
          <GridElement
            key={index}
            imageUrl={element.imageUrl}
            description={element.description}
            desc={element.desc}
          />
        ))}
      </div>
    </div>
  );
};

export default Grid;
