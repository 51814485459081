import React from "react";
// import { Facebook, Twitter, Instagram } from "@mui/icons-material";

const Footer = () => {
  return (
    <footer className="bg-gray-200 text-gray-700 py-8">
      <div className="container mx-auto px-4 lg:px-0 flex flex-col lg:flex-row items-center justify-around">
        {/* 1. Logo and Navigation Links */}
        <div className="flex items-center justify-center lg:justify-start mb-4 lg:mb-0">
          <img
            src="/assets/images/Frame.png"
            alt="Logo"
            style={{ height: "50px", marginRight: "20px" }}
          />
          {/* <div className="flex flex-wrap justify-center lg:justify-start">
            <a href="#" className="mx-3 my-2 lg:mx-2 lg:my-0">
              Home
            </a>
            <a href="#" className="mx-3 my-2 lg:mx-2 lg:my-0">
              About Us
            </a>
            <a href="#" className="mx-3 my-2 lg:mx-2 lg:my-0">
              Services
            </a>
            <a href="#" className="mx-3 my-2 lg:mx-2 lg:my-0">
              Contact
            </a>
          </div> */}
        </div>

        {/* 2. Contact Information */}
        <div className="mt-8 lg:mt-0 lg:w-1/4 lg:text-left">
          <h3 className="text-lg font-bold text-[#496148]">Contact Us</h3>
          <p className="text-xl my-2 font-semibold text-[#496148]">Harshi Realtors</p>
          <p className="text-xl mb-2 font-semibold text-[#496148]">
            Marathahalli, Bangalore
          </p>
          <p className="text-xl mb-2 font-semibold text-[#496148]">
            Call: +91-7899742012
          </p>
          <p className="text-xl font-semibold text-[#496148]">
            Email: harshirealtors@gmail.com
          </p>
        </div>

        {/* 4. Social Media Icons */}
        {/* <div className="flex justify-center lg:justify-end mt-8 lg:mt-0 lg:w-1/4">
          <Facebook className="text-gray-700 mx-2 cursor-pointer" />
          <Twitter className="text-gray-700 mx-2 cursor-pointer" />
          <Instagram className="text-gray-700 mx-2 cursor-pointer" />
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
