import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Tooltip } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";

function Header() {
  return (
    <div className="header_container mb-12">
      <div className="header mx-auto px-[20px] max-w-[1800px]">
        <AppBar
          position="static"
          sx={{
            backgroundColor: "white",
            boxShadow: "none",
          }}
        >
          <div className="flex flex-col items-center lg:items-center justify-center lg:flex-row md:flex-row p-8 relative">
            <div style={{ textAlign: "center" }}>
              <Link to="/">
                <img
                  src="/assets/images/Frame.png"
                  alt="Logo"
                  style={{ height: "50px" }} 
                />
              </Link>
            </div>
            <div className="md:absolute md:top-45 md:right-10 text-right">
              <Tooltip title="Call Us" placement="left-start">
                <p className="font-bold text-[#496148]">
                  {" "}
                  <CallIcon fontSize="medium" className="text-[#496148]" />
                  +91-7899742012
                </p>
              </Tooltip>
            </div>
          </div>
        </AppBar>
      </div>
    </div>
  );
}

export default Header;
