import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const items = [
  <div className="bg-[#F1F2F2] rounded-3xl shadow-md p-6">
    <div className="mb-4 flex justify-between items-center">
      <img src="/assets/images/quote.png" alt="quote" />
      <div>
        <h2 className="text-3xl font-semibold text-gray-800">
          Madhu Jain, USA
        </h2>
      </div>
    </div>
    <div className="text-gray-700">
      <p className="text-lg text-gray-600 mb-2">
        "I am happy with the services, Every thing was well communicated and
        within schedule. Thank you Harshi Realtors for the excellent job."
      </p>
      {/* <p className="text-lg text-gray-600">Great service</p> */}
    </div>
  </div>,
  <div className="bg-[#F1F2F2] rounded-3xl shadow-md p-6">
    <div className="mb-4 flex justify-between items-center">
      <img src="/assets/images/quote.png" alt="quote" />
      <div>
        <h2 className="text-3xl font-semibold text-gray-800">Harish R, USA</h2>
      </div>
    </div>
    <div className="text-gray-700">
      <p className="text-lg text-gray-600 mb-2">
        "The Property was shown to multiple clients, They helped us with the
        rental negotiations and the team did a good job in getting the flat
        painted and fixing / replacing the furniture’s."
      </p>
      {/* <p className="text-lg text-gray-600">Great service</p> */}
    </div>
  </div>,
  <div className="bg-[#F1F2F2] rounded-3xl shadow-md p-6">
    <div className="mb-4 flex justify-between items-center">
      <img src="/assets/images/quote.png" alt="quote" />
      <div>
        <h2 className="text-3xl font-semibold text-gray-800">
          Priyanka Jain, USA
        </h2>
      </div>
    </div>
    <div className="text-gray-700">
      <p className="text-lg text-gray-600 mb-2">
        "Managing your property while staying in USA is difficult. Harshi
        Realtors helped me overcome the difficulty, now they take care of my
        property for me and I don’t have to get bothered by the tenants or the
        society or municipal taxes."
      </p>
      {/* <p className="text-lg text-gray-600">Great service</p> */}
    </div>
  </div>,
];

function Reviews() {
  return (
    <div className="mx-auto max-w-[1800px] px-[20px] mb-[80px]">
      <div className="feature_title mb-[40px]">
        <p className="text-center font-light mb-2">What you will get</p>
        <h1 className="text-[44px] font-semibold text-center">
          Hear from our clients{" "}
        </h1>
      </div>
      <div className="w-full ">
        <AliceCarousel mouseTracking items={items} />
      </div>
    </div>
  );
}

export default Reviews;
