import React from "react";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import WhyUs from "../components/WhyUs";
import Features from "../components/Features";
import Reviews from "../components/Reviews";
import Footer from "../components/Footer";
import NriFeatures from "../components/NriFeatures";
import Grid from "../components/Grid";
import WhatsAppButton from "../components/WhatsaapButton";

function Home() {
  return (
    <div>
      <WhatsAppButton />
      <Header />
      <HeroSection />
      {/* <WhyUs /> */}
      <NriFeatures />
      <Grid />
      <Features />
      <Reviews />
      <Footer />
    </div>
  );
}

export default Home;
