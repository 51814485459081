import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Reviews from "../components/Reviews";
import HeroSection from "../components/HeroSection";
import Grid from "../components/Grid";

function PropertyManagementServices() {
  return (
    <div>
      <Header />
      <HeroSection />
      <Grid />
      <Reviews />
      <Footer />
    </div>
  );
}

export default PropertyManagementServices;
