import React from "react";
// import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

function NriFeatures() {
  return (
    <div className="nri_container bg-[#3E5744] mb-[50px]">
      <div className="nri mx-auto max-w-[1800px] px-[30px] pt-[50px]">
        <h1 className="text-[44px] font-semi-bold text-center text-white mb-[100px]">
          NRI Property Management Services in Bangalore
        </h1>

        <div className="flex justify-around items-center gap-[100px]">
          <div className="content" style={{ width: "75%" }}>
            {/* <p className="text-white font-semi-bold text-[24px] mb-[10px]">
              For Whom ?
            </p> */}
            <p className="text-white font-semi-bold text-[34px] ">
              NRIs having any kind of residential / commercial property in
              Bangalore
            </p>
            <p className="text-white font-semi-bold text-[34px] mb-[70px]">
              and looking out to put their property on Rent.
            </p>
            {/* <p className="text-white font-semi-bold text-[24px]">
              {" "}
              Explore Now <TrendingFlatIcon fontSize="large" />
            </p> */}
          </div>

          <div className="hidden lg:block md:block" style={{ width: "25%" }}>
            <div className="rounded-3xl shadow-none bg-[#4F8960] p-5 mb-[50px]">
              <img src="assets/images/blr.png" alt="" className=" w-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NriFeatures;
